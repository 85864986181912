import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "../style/logo.less";

export default function() {
    const query = useStaticQuery(graphql`
        query LogoQuery {
            site {
                siteMetadata {
                    title
                    logoTitle
                    logo
                }
            }
        }
    `);
    return (
        <span className="logo">
            {query.site.siteMetadata.logoTitle}
        </span>
        
    );
}
