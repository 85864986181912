import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation  } from "@reach/router";

function SEO({ description, lang, meta, title, image }) {
    
    const location = useLocation();
    // console.log(location.href);
    

    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        seoTitle
                        description
                        author
                        logo
                        twitterSite
                        twitterCreator
                        siteUrl
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const ogImage =  image || site.siteMetadata.logo;
    const ogUrl = location.href;

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate={
                title === site.siteMetadata.title
                    ? `${title} ${site.siteMetadata.seoTitle}`
                    : `%s | ${site.siteMetadata.title} ${site.siteMetadata.seoTitle}`
            }
            meta={[
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    property: `og:title`,
                    content: title
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    property: `og:image`,
                    content: site.siteMetadata.siteUrl + ogImage
                },
                {
                    property: `og:url`,
                    content: ogUrl
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`
                },
                {
                    name: `twitter:site`,
                    content: site.siteMetadata.twitterSite
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.twitterCreator
                }
            ].concat(meta)}
        />
    );
}

SEO.defaultProps = {
    lang: `tr`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired
};


export default SEO;
